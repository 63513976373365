.apexcharts-xaxis-label {
    font-family: Montserrat, sans-serif !important;
    text-anchor: middle;
    font-size: 12px;
    font-weight: 400;
}

.apexcharts-canvas .apexcharts-pie .apexcharts-pie-series .apexcharts-pie-area {
    stroke-width: 0;
}

.apexcharts-canvas .apexcharts-pie .apexcharts-pie-series .apexcharts-pie-area {
    stroke-width: 0;
}

.apexcharts-legend.apexcharts-align-center.apx-legend-position-right {
    display: flex;
    justify-content: space-evenly;
}

.apexcharts-legend.apexcharts-align-center.apx-legend-position-bottom .apexcharts-legend-text,
.apexcharts-legend.apexcharts-align-center.apx-legend-position-right .apexcharts-legend-text {
    font-family: Montserrat, sans-serif !important;
    text-anchor: middle;
    font-size: 12px;
    font-weight: 400;
}