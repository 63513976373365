#chart_2 .apexcharts-series[seriesName='Earning'] {
    transform: scaleY(0.990);
}

#chart_2 .apexcharts-series[seriesName='Expense'] {
    transform: scaleY(1.010);
}


#revenue-report .apexcharts-series[seriesName='Earning'] {
    transform: scaleY(0.965);
}

#revenue-report .apexcharts-series[seriesName='Expense'] {
    transform: scaleY(1.035);
}