.grid-1 {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(6, 1fr);
  grid-column-gap: 16px;
  grid-row-gap: 16px;
}

.div1 {
  grid-area: 1 / 1 / 4 / 3;
}
.div2 {
  grid-area: 1 / 3 / 4 / 4;
}
.div3 {
  grid-area: 1 / 4 / 2 / 5;
}
.div4 {
  grid-area: 2 / 4 / 3 / 5;
}
.div5 {
  grid-area: 3 / 4 / 4 / 5;
}
.div6 {
  grid-area: 4 / 1 / 7 / 3;
}
.div7 {
  grid-area: 4 / 3 / 7 / 5;
}

.grid-2 {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
}

.grid-2-div1 {
  grid-area: 1 / 1 / 2 / 2;
}
.grid-2-div2 {
  grid-area: 1 / 2 / 2 / 3;
}

@media only screen and (max-width: 1204px) {
  .grid-1 {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(12, 1fr);
    grid-column-gap: 16px;
    grid-row-gap: 16px;
  }

  .div1 {
    grid-area: 1 / 1 / 4 / 3;
  }
  .div2 {
    grid-area: 4 / 1 / 7 / 2;
  }
  .div3 {
    grid-area: 4 / 2 / 5 / 3;
  }
  .div4 {
    grid-area: 5 / 2 / 6 / 3;
  }
  .div5 {
    grid-area: 6 / 2 / 7 / 3;
  }
  .div6 {
    grid-area: 7 / 1 / 10 / 3;
  }
  .div7 {
    grid-area: 10 / 1 / 13 / 3;
  }
}

@media only screen and (max-width: 655px) {
  .grid-1 {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(2, 1fr);
    grid-column-gap: 16px;
    grid-row-gap: 16px;
  }

  .div1 {
    grid-area: 1 / 1 / 2 / 2;
  }
  .div2 {
    grid-area: 2 / 1 / 3 / 2;
  }
  .div3 {
    grid-area: 3 / 1 / 4 / 2;
  }
  .div4 {
    grid-area: 4 / 1 / 5 / 2;
  }
  .div5 {
    grid-area: 5 / 1 / 6 / 2;
  }
}
