.adminGrid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-column-gap: 16px;
  grid-row-gap: 16px;
}

.admin-item1 {
  grid-area: 1 / 1 / 3 / 3;
  background-color: white;
}
.admin-item2 {
  grid-area: 1 / 3 / 2 / 4;
  background-color: white;
}
.admin-item3 {
  grid-area: 2 / 3 / 3 / 4;
  background-color: white;
}
.admin-item4 {
  grid-area: 1 / 4 / 2 / 5;
  background-color: white;
}
.admin-item5 {
  grid-area: 2 / 4 / 3 / 5;
  background-color: white;
}
/* .admin-item6 {
  grid-area: 3 / 1 / 5 / 2;
  background-color: white;
}
.admin-item7 {
  grid-area: 3 / 2 / 5 / 3;
  background-color: white;
}
.admin-item8 {
  grid-area: 3 / 3 / 5 / 5;
  background-color: white;
}
.admin-item9 {
  grid-area: 5 / 1 / 7 / 3;
  background-color: white;
}
.admin-item10 {
  grid-area: 5 / 3 / 7 / 5;
  background-color: white;
} */

.card {
  background-color: white;
}
