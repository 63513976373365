.grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 13px;
  grid-row-gap: 13px;
}

.item1 {
  grid-area: 1 / 1 / 2 / 2;
}

.item2 {
  grid-area: 2 / 1 / 3 / 2;
}

.item3 {
  grid-area: 3 / 1 / 4 / 2;
}

.item4 {
  grid-area: 1 / 2 / 2 / 3;
}

.item5 {
  grid-area: 2 / 2 / 3 / 3;
}

.item6 {
  grid-area: 3 / 2 / 4 / 3;
}

@media only screen and (max-width: 549px) {
  .grid {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
}