.recharts-cartesian-axis-tick-line {
  display: none;
}
.locationsTable::-webkit-scrollbar {
  display: none;
}

.parent {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(3, 1fr);
  grid-column-gap: 16px;
  grid-row-gap: 16px;
}

.second-row {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(3, 1fr);
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  margin-top: 16px;
}

.third-row {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(3, 1fr);
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  margin-top: 16px;
}

.gridItem-1 {
  grid-area: 1 / 1 / 4 / 3;
  background-color: white;
  border-radius: 6px;
}

.gridItem-2 {
  grid-area: 1 / 3 / 4 / 4;
  background-color: white;
  border-radius: 6px;
}

.gridItem-3 {
  grid-area: 3 / 3 / 4 / 4;
  background-color: white;
  border-radius: 6px;
  display: flex;
  justify-content: space-between;
  padding: 16px;
}

.gridItem-4 {
  grid-area: 1 / 4 / 2 / 5;
  background-color: white;
  border-radius: 6px;
}

.gridItem-5 {
  grid-area: 2 / 4 / 3 / 5;
  background-color: white;
  border-radius: 6px;
}

/* .gridItem-6 {
  grid-area: 3 / 4 / 4 / 5;
  background-color: white;
  border-radius: 6px;
} */

.secondRowGridItem-1 {
  grid-area: 1 / 1 / 4 / 2;
  background-color: white;
  border-radius: 6px;
}

.secondRowGridItem-2 {
  grid-area: 1 / 2 / 4 / 3;
  background-color: white;
  border-radius: 6px;
}

.secondRowGridItem-3 {
  grid-area: 1 / 3 / 4 / 5;
  background-color: white;
  border-radius: 6px;
}

.third-row {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(3, 1fr);
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  margin-top: 16px;
}

.thirdRowGridItem-1 {
  grid-area: 1 / 1 / 4 / 3;
  background-color: white;
  border-radius: 6px;
}

.thirdRowGridItem-2 {
  grid-area: 1 / 3 / 4 / 5;
  background-color: white;
  border-radius: 6px;
}

.fourth-row {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(1, 1fr);
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  margin-top: 16px;
}

.fourthRowGridItem-1 {
  grid-area: 1 / 1 / 2 / 3;
  background-color: white;
  border-radius: 6px;
}

.fourthRowGridItem-2 {
  grid-area: 1 / 3 / 2 / 4;
  background-color: white;
  border-radius: 6px;
}

.fourthRowGridItem-3 {
  grid-area: 1 / 4 / 2 / 5;
  background-color: white;
  border-radius: 6px;
}

@media only screen and (max-width: 1180px) {
  .parent {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(6, 1fr);
    grid-column-gap: 16px;
    grid-row-gap: 16px;
  }

  .gridItem-1 {
    grid-area: 1 / 1 / 4 / 3;
  }
  .gridItem-2 {
    grid-area: 4 / 1 / 7 / 2;
  }
  .gridItem-3 {
    grid-area: 4 / 2 / 5 / 3;
  }
  .gridItem-4 {
    grid-area: 5 / 2 / 6 / 3;
  }
  .gridItem-5 {
    grid-area: 6 / 2 / 7 / 3;
  }
  /* .gridItem-6 {
    grid-area: 6 / 2 / 7 / 3;
  } */

  .second-row {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-column-gap: 16px;
    grid-row-gap: 16px;
    margin-top: 16px;
  }
  .secondRowGridItem-2 {
    grid-area: 1 / 1 / 2 / 2;
  }
  .secondRowGridItem-1 {
    grid-area: 1 / 2 / 2 / 3;
  }
  .secondRowGridItem-3 {
    grid-area: 2 / 1 / 3 / 3;
  }

  .third-row {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-column-gap: 16px;
    grid-row-gap: 16px;
    margin-top: 16px;
  }

  .thirdRowGridItem-1 {
    grid-area: 1 / 1 / 2 / 3;
  }
  .thirdRowGridItem-2 {
    grid-area: 2 / 1 / 3 / 3;
  }

  .fourth-row {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-column-gap: 16px;
    grid-row-gap: 16px;
    margin-top: 16px;
  }

  .fourthRowGridItem-1 {
    grid-area: 1 / 1 / 2 / 3;
  }
  .fourthRowGridItem-2 {
    grid-area: 2 / 1 / 3 / 2;
  }
  .fourthRowGridItem-3 {
    grid-area: 2 / 2 / 3 / 3;
  }
}

@media only screen and (max-width: 549px) {
  .parent {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(6, 1fr);
    grid-column-gap: 16px;
    grid-row-gap: 16px;
  }
  .gridItem-1 {
    grid-area: 1 / 1 / 3 / 3;
  }
  .gridItem-2 {
    grid-area: 5 / 1 / 7 / 3;
  }

  .gridItem-3 {
    grid-area: 3 / 1 / 4 / 2;
  }

  .gridItem-4 {
    grid-area: 4 / 1 / 5 / 2;
  }
  .gridItem-5 {
    grid-area: 3 / 2 / 4 / 3;
  }
  .gridItem-6 {
    grid-area: 4 / 2 / 5 / 3;
  }

  .second-row {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(3, 1fr);
    grid-column-gap: 16px;
    grid-row-gap: 16px;
    margin-top: 16px;
  }
  .secondRowGridItem-2 {
    grid-area: 1 / 1 / 2 / 3;
  }
  .secondRowGridItem-1 {
    grid-area: 2 / 1 / 3 / 3;
  }
  .secondRowGridItem-3 {
    grid-area: 3 / 1 / 4 / 3;
  }

  .third-row {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(2, 1fr);
    grid-column-gap: 16px;
    grid-row-gap: 16px;
    margin-top: 16px;
  }
  .thirdRowGridItem-2 {
    grid-area: 1 / 1 / 2 / 2;
  }
  .thirdRowGridItem-1 {
    grid-area: 2 / 1 / 3 / 2;
  }

  .fourth-row {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: repeat(3, 1fr);
    grid-column-gap: 16px;
    grid-row-gap: 16px;
    margin-top: 16px;
  }
  .fourthRowGridItem-1 {
    grid-area: 1 / 1 / 2 / 2;
  }
  .fourthRowGridItem-2 {
    grid-area: 2 / 1 / 3 / 2;
  }
  .fourthRowGridItem-3 {
    grid-area: 3 / 1 / 4 / 2;
  }
}
